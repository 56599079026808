// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";






// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCwZUYTm1SAbPknZBqxVsvJALFG8oxNxhQ",
  authDomain: "creative-monkey-ecosystem.firebaseapp.com",
  projectId: "creative-monkey-ecosystem",
  storageBucket: "creative-monkey-ecosystem.appspot.com",
  messagingSenderId: "850348107694",
  appId: "1:850348107694:web:df7c15210715fef61eca3f",
  measurementId: "G-8SBVWD4H1Z"
};




// Initialize Firebase
const app = initializeApp(firebaseConfig);
const cloud = getFunctions(app)
const analytics = getAnalytics(app);
const auth = getAuth(app);



export { app, cloud, analytics, auth };